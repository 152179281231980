// WeekdaysHeader.tsx
import React from 'react';

const WeekdaysHeader: React.FC = () => {
  const weekdays = ['Ma', 'Ti', 'Ke', 'To', 'Pe', 'La', 'Su'];

  return (
    <div className="weekdays-header">
      {weekdays.map((day, index) => (
        <div className="weekday" key={index}>
          {day}
        </div>
      ))}
    </div>
  );
};

export default WeekdaysHeader;
