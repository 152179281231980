import React from 'react';
import { format } from 'date-fns';
import { fi } from 'date-fns/locale';
import { CalendarDayPropsType } from '../../types';

const CalendarDay: React.FC<CalendarDayPropsType> = ({
  dayNumberOfMonth,
  dayNumberOfWeek,
  dayNumberOfYear,
  year,
  monthNumber,
  isFirstOfMonth,
  isLastOfMonth,
  isPublicHolidayInMyCountry,
  isToday,
  isPast,
  isSelected,
  inSelectedRange,
  inSelectedRangeHover,
  onClick,
  onMouseDown,
  onMouseUp,
  onMouseEnter,
  onTouchStart,
  onTouchEnd,
  events = [],
}) => {
  const weekdayClass = `wd${dayNumberOfWeek}`;
  const getMonthNameInFinnish = (monthNumber: number) => {
    const currentYear = new Date().getFullYear();
    const date = new Date(currentYear, monthNumber - 1);
    const monthNamePartitive = format(date, 'MMMM', { locale: fi });
  
    return monthNamePartitive.slice(0, -5);
  };
  
  const getYearSuffix = (year: number) => {
    // Return the last two digits of the year if it's not the current year
    const currentYear = new Date().getFullYear();
    return year !== currentYear ? ` ${year % 100}` : '';
  };

  return (
    <button
      className={`day ${weekdayClass} ${isPast ? 'past' : ''} ${isToday ? 'today' : ''} ${isSelected ? 'selected' : ''} ${inSelectedRange ? 'inSelectedRange' : ''} ${inSelectedRangeHover ? 'inSelectedRangeHover' : ''} ${monthNumber % 2 === 0 ? 'even-month' : 'odd-month'}`}
      onClick={onClick}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      onMouseEnter={onMouseEnter}
      onTouchStart={onTouchStart}
      onTouchEnd={onTouchEnd}
    >
      <div className="day-content">
        {isFirstOfMonth && (
          <div className="monthLabel">
          {getMonthNameInFinnish(monthNumber)}
          {getYearSuffix(year)}
        </div>
        )}
        <div className="day-number">{dayNumberOfMonth}</div>
        {isToday && (
          <div className='todayFiller'></div>
        )}
        <div className="event-list">
          {events.map((event, index) => (
            <div key={index} className={`event ${event.rangeClass}`}>
              <div className="eventTimeWrapper">
                <span className="startTime">{event.startTime}</span>
                {event.endTime && (
                  <>
                  <span className="timeDivider"> - </span>
                <span className="endTime">{event.endTime}</span>
                  </>
                )}
              </div>
              <span className="eventTitle">{event.eventTitle}</span>
              {event.tags && event.tags.length > 0 && (
                <span className="eventTags">{event.tags.join(', ')}</span>
              )}
              {event.variables && Object.keys(event.variables).length > 0 && (
                <span className="eventVariables">
                  {Object.entries(event.variables)
                    .map(([key, value]) => `${key}=${value}`)
                    .join(', ')}
                </span>
              )}
            </div>
          ))}
        </div>
      </div>
    </button>
  );
};

export default CalendarDay;
