import React, { useState, useEffect } from 'react';
import '../EventPanel/eventPanel.css';
import { addEventToCalendar, deleteEventFromCalendar, updateEventInCalendar } from '../../eventService';
import Icon from '@mdi/react';
import { mdiClose, mdiDelete, mdiContentSave } from '@mdi/js';
import { EventType, CalendarType, EventPanelProps } from '../../types';
import { formatFullDateWithDayName, formatShortDayAndDate, formatFullDateWithYear, calculateDateDifference } from '../utils/timeUtils';
import { setDate } from 'date-fns';


const EventPanel: React.FC<EventPanelProps> = ({
  selectedDay,
  selectedRange,
  onClearSelection,
  onDayClick,
  userId,
  calendars,
  events,
  selectedCalendars,
  userName, // Destructure userName from props
}) => {
  const [newEventRaw, setNewEventRaw] = useState<string>('');
  const [newEventTitle, setNewEventTitle] = useState<string>('');
  const [selectedCalendarForEvent, setSelectedCalendarForEvent] = useState<string | null>(null);
  const [selectedEventId, setSelectedEventId] = useState<string | null>(null);
  const [isEventManagerActive, setEventManagerActive] = useState<boolean>(false);
  const [sniffedStartTime, setSniffedStartTime] = useState<string | null>(null);
  const [sniffedEndTime, setSniffedEndTime] = useState<string | null>(null);
  const [sniffedDuration, setSniffedDuration] = useState<string | null>(null);
  const [sniffedTags, setSniffedTags] = useState<string[]>([]); // For storing tags
  const [sniffedVariables, setSniffedVariables] = useState<{ [key: string]: string }>({}); // For storing variables
  const [updatedStartDay, setUpdatedStartDay] = useState<string | null>(null);
  const [updatedEndDay, setUpdatedEndDay] = useState<string | null>(null);
  const [eventStartDay, setEventStartDay] = useState<string | null>(null);
  const [eventEndDay, setEventEndDay] = useState<string | null>(null);
  const [localCalendarOrder, setLocalCalendarOrder] = useState<string[]>([]);



  // Set default calendar when event manager is reset or when a new event is being created
  useEffect(() => {
    if (!selectedEventId) {
      const calendarIds = Object.keys(calendars);

      if (calendarIds.length > 0) {
        // Jos localCalendarOrder on tyhjä, aseta oletuksena ensimmäinen kalenteri
        if (localCalendarOrder.length === 0) {
          setLocalCalendarOrder(calendarIds); // Asetetaan kaikki kalenterit järjestyksessä
          setSelectedCalendarForEvent(calendarIds[0]); // Oletuksena ensimmäinen
        } else {
          setSelectedCalendarForEvent(localCalendarOrder[0]); // Asetetaan viimeksi käytetty kalenteri
        }
      }
    }
  }, [calendars, selectedEventId, localCalendarOrder]);

  // Use selected days if no event selected
  useEffect(() => {
    if (!selectedEventId) {
      // Päivitetään eventStartDay ja eventEndDay kalenterin valinnoista
      setEventStartDay(selectedRange ? selectedRange[0] : selectedDay);
      setEventEndDay(selectedRange ? selectedRange[1] : null);
    }
  }, [selectedDay, selectedRange, selectedEventId]);

  // Reset event manager when day changes
  useEffect(() => {
    resetEventManager();
  }, [selectedDay]);

  const handleAddEvent = async () => {
    const startDay = eventStartDay;
    const endDay = eventEndDay;

    if (!startDay || !selectedCalendarForEvent || newEventRaw.trim() === '') {
      console.error("Missing required fields.");
      return;
    }

    try {
      if (selectedEventId) {
        const oldEventData = events.find(event => event.id === selectedEventId); // Fetch the old event data
        if (!oldEventData) {
          console.error("Old event data not found.");
          return;
        }

        await updateEventInCalendar(
          selectedEventId,
          selectedCalendarForEvent,
          startDay,
          newEventRaw,
          newEventTitle,
          sniffedStartTime,
          sniffedEndTime,
          sniffedDuration,
          endDay,
          sniffedTags,
          sniffedVariables,
          oldEventData, // Pass the old event data for logging
          userId,
          userName
        );
      } else {
        await addEventToCalendar(
          selectedCalendarForEvent,
          startDay,
          newEventRaw,
          newEventTitle,
          sniffedStartTime,
          sniffedEndTime,
          sniffedDuration,
          endDay,
          sniffedTags,
          sniffedVariables,
          userId,
          userName
        );
      }

      // Päivitä localCalendarOrder ja siirrä käytetty kalenteri ensimmäiseksi
    setLocalCalendarOrder((prevOrder) => [selectedCalendarForEvent!, ...prevOrder.filter(id => id !== selectedCalendarForEvent)]);

      resetEventManager();
    } catch (error) {
      console.error("Error adding or updating event:", error);
    }
  };

  const handleDeleteEventFromCalendar = async () => {
    if (!selectedEventId) return;
    try {
      const eventToDelete = events.find(event => event.id === selectedEventId);

            // Päivitä localCalendarOrder ja siirrä käytetty kalenteri ensimmäiseksi
    setLocalCalendarOrder((prevOrder) => [selectedCalendarForEvent!, ...prevOrder.filter(id => id !== selectedCalendarForEvent)]);
      if (!eventToDelete) {
        console.error("Event not found for deletion.");
        return;
      }

      const eventTitle = eventToDelete.eventTitle || 'Unnamed Event';

      await deleteEventFromCalendar(
        selectedEventId,
        selectedCalendarForEvent!,
        eventTitle,
        userId,
        userName
      );
      resetEventManager();
    } catch (error) {
      console.error("Error deleting event:", error);
    }
  };


  const resetEventManager = () => {
    setNewEventRaw('');
    setNewEventTitle('');
    // setSelectedCalendarForEvent(Object.keys(calendars)[0] || null);
    setSelectedEventId(null);
    setEventManagerActive(false);
    setSniffedStartTime(null);
    setSniffedEndTime(null);
    setSniffedDuration(null);
    setSniffedTags([]);
    setSniffedVariables({});

    // Palauta päivämäärät kalenterin valintoihin
    setEventStartDay(selectedRange ? selectedRange[0] : selectedDay);
    setEventEndDay(selectedRange ? selectedRange[1] : null);
  };

  const handleEventClick = (
    eventId: string,
    eventRaw: string,
    calendarId: string,
    date: string,
    endDate: string | null
  ) => {
    // Jos sama tapahtuma valitaan uudelleen, suljetaan muokkaus
    if (selectedEventId === eventId) {
      resetEventManager(); // Sulje tapahtuman muokkaus
    } else {
      // Asetetaan valittu tapahtuma
      setSelectedEventId(eventId);
      setNewEventRaw(eventRaw);
      setSelectedCalendarForEvent(calendarId);

      // Päivitä päivät tapahtuman päiviksi
      setEventStartDay(date);
      setEventEndDay(endDate);

      // Triggeröi snifferit
      handleInputChange({ target: { value: eventRaw } } as React.ChangeEvent<HTMLInputElement>);

      setEventManagerActive(true); // Näytetään tapahtumanhallinta
    }
  };


  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value;
    setNewEventRaw(input);
    setEventManagerActive(true);

    // Split by space, hyphen, or period
    const sniffedItems = input.split(/[\s-]+/); // Split by spaces

    let remainingText = ""; // To store the remaining text
    let startTime: string | null = null;
    let endTime: string | null = null;
    const tags: string[] = []; // To store tags
    const variables: { [key: string]: string } = {}; // To store variables


    sniffedItems.forEach((item) => {
      // Updated regex for time detection to support 1430, 14:30, and 14.30
      const timeRegex = /^([01]?[0-9]|2[0-3])([0-5][0-9])?$|^([01]?[0-9]|2[0-3])[:.]([0-5][0-9])$/;

      // Tag regex for detecting hashtags
      const tagRegex = /^#\w+/;

      // Variable regex for detecting variables in the format $variable=value
      const variableRegex = /^\$(\w+)=([\w\d]+)/;

      const match = item.match(timeRegex);
      const matchTag = item.match(tagRegex);
      const matchVariable = item.match(variableRegex);

      if (match) {
        const formatTime = (hours: string, minutes: string) => {
          const formattedMinutes = minutes || '00'; // Default to "00" if minutes are missing
          return `${hours.padStart(2, '0')}:${formattedMinutes.padStart(2, '0')}`;
        };

        if (!startTime) {
          // If no start time, set start time
          const hours = match[1] || match[3];
          const minutes = match[2] || match[4] || '00';
          startTime = formatTime(hours!, minutes!);
          setSniffedStartTime(startTime);
        } else if (!endTime) {
          // If start time exists, set end time
          const hours = match[1] || match[3];
          const minutes = match[2] || match[4] || '00';
          endTime = formatTime(hours!, minutes!);
          const duration = calculateDuration(startTime, endTime);
          setSniffedEndTime(endTime);
          setSniffedDuration(duration);
        }
      } else if (matchTag) {
        // If a tag is detected, store it in the tags array
        tags.push(matchTag[0]);
      } else if (matchVariable) {
        // If a variable is detected, store it in the variables object
        variables[matchVariable[1]] = matchVariable[2];
      } else {
        // If no match, it's part of the event text
        remainingText += `${item} `;
      }
    });

    // Trim and set the remaining text to the "Teksti" input
    setNewEventTitle(remainingText.trim());

    // Set tags and variables
    setSniffedTags(tags);
    setSniffedVariables(variables);

    if (!startTime) {
      setSniffedStartTime(null);
      setSniffedEndTime(null);
      setSniffedDuration(null);
    }
  };

  // Function to calculate duration between two times
  const calculateDuration = (start: string, end: string): string => {
    const [startHour, startMinute] = start.split(':').map(Number);
    const [endHour, endMinute] = end.split(':').map(Number);

    let durationMinutes = (endHour * 60 + endMinute) - (startHour * 60 + startMinute);
    if (durationMinutes < 0) {
      durationMinutes += 24 * 60; // Handle overnight times
    }

    const hours = Math.floor(durationMinutes / 60);
    const minutes = durationMinutes % 60;
    return `${hours}h ${minutes}m`;
  };


  const getEventsForSelectedDay = () => {
    if (!selectedDay) return [];

    // Split into timed and untimed events
    const untimedEvents = events
      .filter(event => event.date === selectedDay && selectedCalendars.includes(event.calendarId) && !event.startTime)
      .sort((a, b) => a.eventRaw.localeCompare(b.eventRaw)); // Aakkosjärjestys

    const timedEvents = events
      .filter(event => event.date === selectedDay && selectedCalendars.includes(event.calendarId) && event.startTime)
      .sort((a, b) => (a.startTime || "").localeCompare(b.startTime || "")); // Aikajärjestys

    // Merge untimed events first, then timed events
    return [...untimedEvents, ...timedEvents];
  };

  const getEventsForSelectedDayOrRange = () => {
    const getEventDuration = (event: EventType) => {
      const start = new Date(event.date).getTime();
      const end = event.endDate ? new Date(event.endDate).getTime() : start;
      return end - start;
    };

    const sortByDurationAndStart = (a: EventType, b: EventType) => {
      const durationA = getEventDuration(a);
      const durationB = getEventDuration(b);

      if (durationA !== durationB) {
        return durationB - durationA;
      } else {
        return new Date(a.date).getTime() - new Date(b.date).getTime();
      }
    };

    const processEvents = (eventsForDay: EventType[]) => {
      const rangeEvents = eventsForDay
        .filter(event => event.endDate && event.date !== event.endDate)
        .sort(sortByDurationAndStart);

      const untimedEvents = eventsForDay
        .filter(event => !event.startTime && (!event.endDate || event.date === event.endDate))
        .sort((a, b) => a.eventRaw.localeCompare(b.eventRaw));

      const timedEvents = eventsForDay
        .filter(event => event.startTime)
        .sort((a, b) => {
          const timeCompare = (a.startTime || '').localeCompare(b.startTime || '');
          if (timeCompare !== 0) return timeCompare;
          return sortByDurationAndStart(a, b);
        });

      return [...rangeEvents, ...untimedEvents, ...timedEvents];
    };

    if (selectedDay) {
      // Jos yksittäinen päivä on valittuna
      const eventsForDay = events.filter(event => {
        const eventStart = event.date;
        const eventEnd = event.endDate || event.date; // Jos ei ole loppupäivää, käytetään samaa kuin aloituspäivä

        // Tarkista, osuuko tapahtuma valittuun päivään
        return (
          selectedCalendars.includes(event.calendarId) &&
          selectedDay >= eventStart &&
          selectedDay <= eventEnd
        );
      });

      return processEvents(eventsForDay);
    }

    if (selectedRange) {
      const [startDay, endDay] = selectedRange;

      // Jos aikajakso on valittuna, haetaan tapahtumat, jotka osuvat tuolle välille
      const eventsForRange = events.filter(event => {
        const eventStart = event.date;
        const eventEnd = event.endDate || event.date;

        // Tarkista, osuuko tapahtuma valittuun aikajaksoon
        return (
          selectedCalendars.includes(event.calendarId) &&
          ((eventStart >= startDay && eventStart <= endDay) || // Tapahtuma alkaa valitun ajanjakson sisällä
            (eventEnd >= startDay && eventEnd <= endDay) || // Tapahtuma päättyy valitun ajanjakson sisällä
            (eventStart <= startDay && eventEnd >= endDay)) // Tapahtuma kattaa koko valitun ajanjakson
        );
      });

      return processEvents(eventsForRange);
    }

    return [];
  };




  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault(); // Prevent default form submission behavior
    handleAddEvent(); // Call the function to add the event
  };

  const eventsForSelectedDay = getEventsForSelectedDay();
  const eventsForSelectedPeriod = getEventsForSelectedDayOrRange();

  return (
    <div className={`eventPanel ${selectedDay || selectedRange ? 'expanded' : ''}`}>
      <div className='eventPanelHeader'>{formatFullDateWithDayName(selectedDay)}
        {selectedRange && formatShortDayAndDate(selectedRange[0]) + ' - ' + formatShortDayAndDate(selectedRange[1]) + ' (' + calculateDateDifference(selectedRange[0], selectedRange[1]) + ')'}
        <button onClick={onClearSelection} className='closeDay'>
          <Icon path={mdiClose} size={1} />
        </button>
      </div>
      <div className="eventPanelContent">
        <div className={`eventList ${eventsForSelectedPeriod.length === 0 ? 'empty' : ''}`}>


          {eventsForSelectedPeriod.length > 0 ? (
            <>
              {eventsForSelectedPeriod.map((event) => (
                <div
                  className={`event ${selectedEventId === event.id ? 'selected' : ''}`}
                  key={event.id}
                  onClick={() => handleEventClick(event.id, event.eventRaw, event.calendarId, event.date, event.endDate || null)}
                >
                  <div className="eventTimeWrapper">
                    <span className="startTime">{event.startTime}</span>
                    {event.endTime && (
                      <>
                        <span className="timeDivider"> - </span>
                        <span className="endTime">{event.endTime}</span>
                      </>
                    )}
                  </div>
                  <span className="eventTitle">{event.eventTitle}</span>
                  {event.tags && event.tags.length > 0 && (
                    <span className="eventTags">
                      {event.tags.join(', ')}
                    </span>
                  )}
                  {selectedEventId === event.id && (
                    <button onClick={handleDeleteEventFromCalendar} className='deleteEventButton'>
                      <Icon path={mdiDelete} size={1} />
                    </button>
                  )}

                  {event.variables && Object.keys(event.variables).length > 0 && (
                    <span className="eventVariables">
                      {Object.entries(event.variables).map(([key, value]) => `${key}=${value}`).join(', ')}
                    </span>
                  )}
                </div>
              ))}
            </>
          ) : (
            <p>Ei tapahtumia</p>
          )}
        </div>

        <form onSubmit={handleFormSubmit} className={`eventManager ${isEventManagerActive ? 'active' : ''}`}>

          <div className="emptySpace"></div>

          <div className='eventCalendarWrapper'>
            {localCalendarOrder.map((calendarKey) => (
              <label key={calendarKey} className="calendarRadio">
                <input
                  type="radio"
                  name="eventCalendar"
                  value={calendarKey}
                  checked={selectedCalendarForEvent === calendarKey}
                  onChange={() => setSelectedCalendarForEvent(calendarKey)}
                />
                <span>{calendars[calendarKey].name}</span>
              </label>
            ))}
          </div>
          <div className="eventDetails">
            {newEventTitle && (
              <input
                type="text"
                className='title'
                placeholder="Teksti"
                value={newEventTitle}
                onChange={handleInputChange}
                readOnly
              />
            )}
            <div className="timeDetails">
              <div className="dayRow">
                <input
                  type="text"
                  className='datePicker'
                  placeholder='Alkaa'
                  value={formatFullDateWithYear(eventStartDay) || ''}
                  readOnly
                />
                <input
                  type="text"
                  className='durationdays'
                  placeholder="Kesto pv"
                  value={sniffedDuration || ''}
                  onChange={handleInputChange}
                  readOnly
                />
                <input
                  type="text"
                  className='datePicker'
                  placeholder='Päättyy'
                  value={formatFullDateWithYear(eventEndDay) || ''}
                  readOnly
                />
              </div>

              {sniffedStartTime && (

                <div className="timeRow">

                  <input
                    type="text"
                    className='timePicker'
                    placeholder="Alkaa klo"
                    value={sniffedStartTime || ''}
                    onChange={handleInputChange}
                    readOnly
                  />

                  <input
                    type="text"
                    className='durationTime'
                    placeholder="Kesto tuntia"
                    value={sniffedDuration || ''}
                    onChange={handleInputChange}
                    readOnly
                  />
                  <input
                    type="text"
                    className='timePicker'
                    placeholder="Päättyy klo"
                    value={sniffedEndTime || ''}
                    onChange={handleInputChange}
                    readOnly
                  />

                </div>
              )}

            </div>

          </div>

          {sniffedTags.length > 0 && (
            <input
              type="text"
              placeholder="Tagit"
              value={sniffedTags.join(', ')}
              readOnly
            />
          )}

          {Object.keys(sniffedVariables).length > 0 && (
            <input
              type="text"
              placeholder="Muuttujat"
              value={Object.entries(sniffedVariables).map(([key, value]) => `${key}=${value}`).join(', ')}
              readOnly
            />
          )}

          <div className='inputContainer'>
            <input
              type="text"
              className='eventTextInput'
              placeholder=""
              value={newEventRaw}
              onChange={handleInputChange}
            />
            <button type="submit" className='updateEventButton' disabled={!(selectedDay || selectedRange) || !selectedCalendarForEvent || !newEventRaw.trim()}>
              <Icon path={mdiContentSave} size={1} />
            </button>
          </div>

        </form>
      </div>
    </div>
  );
};

export default EventPanel;
