import { auth, googleProvider } from './firebaseConfig';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut, signInWithPopup, signInAnonymously } from 'firebase/auth';

// Function to register a new user
export const register = async (email: string, password: string) => {
  console.log('Registering new user');
  const userCredential = await createUserWithEmailAndPassword(auth, email, password);
  return userCredential.user;
};

// Function to log in a user with email and password
export const login = async (email: string, password: string) => {
  try {
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    const user = userCredential.user;
    console.log('Logged in:');
    return user; // Palautetaan kirjautunut käyttäjä
  } catch (error) {
    console.error('Login error in authService:', error);
    throw error;
  }
};

// Function to log out a user
export const logout = async () => {
  console.log('Logging out');
  await signOut(auth);
};

// Function to log in a user with Google
export const loginWithGoogle = async () => {
  try {
    const result = await signInWithPopup(auth, googleProvider);
    const user = result.user;
    console.log('Logged in with Google:', user);
    return user;
  } catch (error) {
    console.error('Google login error:', error);
    throw error;
  }
};

// Function to log in a user anonymously
export const loginAnonymously = async () => {
  try {
    const userCredential = await signInAnonymously(auth);
    const user = userCredential.user;
    console.log('Logged in anonymously:', user);
    return user;
  } catch (error) {
    console.error('Anonymous login error:', error);
    throw error;
  }
};
