import React, { useState, useRef } from 'react';
import './calendar.css';
import {
  format,
  addDays,
  startOfWeek,
  isSameDay,
  isBefore,
  startOfDay,
  subWeeks,
  getDate,
  getDay,
  getDayOfYear,
  getMonth,
  isFirstDayOfMonth,
  isLastDayOfMonth,
  isAfter,
} from 'date-fns';
import CalendarDay from '../Calendar/CalendarDay';
import { CalendarPropsType, EventType } from '../../types';

const Calendar: React.FC<CalendarPropsType> = ({
  selectedDay,
  selectedRange,
  onDaySelect,
  onRangeSelect,
  calendars,
  events,
  selectedCalendars,
}) => {
  const currentDate = startOfDay(new Date());
  const [startDay, setStartDay] = useState<Date | null>(null);
  const [hoveredDay, setHoveredDay] = useState<Date | null>(null);
  const [isSelectingRange, setIsSelectingRange] = useState<boolean>(false);
  const longPressTimeout = useRef<NodeJS.Timeout | null>(null);
  const isTouchDevice = useRef(false); // Detect whether we're on a touch device
  const LONG_PRESS_DURATION = 500; // Long press duration for mobile

  // Start from monday
  const startDayOfWeek = startOfWeek(subWeeks(currentDate, 1), { weekStartsOn: 1 });
  const endDay = addDays(startDayOfWeek, 7 * 100);

  const calendar = [];
  let day = startDayOfWeek;
  while (day <= endDay) {
    calendar.push(
      Array(7)
        .fill(0)
        .map(() => {
          const newDay = day;
          day = addDays(day, 1);
          return newDay;
        })
    );
  }

  // Handle long press start for range selection (mobile)
  const handleLongPressStart = (day: Date) => {
    longPressTimeout.current = setTimeout(() => {
      setIsSelectingRange(true);
      setStartDay(day);
    }, LONG_PRESS_DURATION);
  };

  // Handle mouse up or touch end: Cancel long press if not triggered
  const handleLongPressCancel = () => {
    if (longPressTimeout.current) {
      clearTimeout(longPressTimeout.current);
      longPressTimeout.current = null;
    }
  };

  // Handle range selection end (user clicks on second day)
  const handleRangeEnd = (day: Date) => {
    if (isSelectingRange && startDay) {
      onRangeSelect(formatDay(startDay), formatDay(day));
      setIsSelectingRange(false);
      setStartDay(null);
    }
  };

  const getEventsForDay = (day: Date): (EventType & { rangeClass: string })[] => {
    const formattedDay = format(day, 'yyyy-MM-dd');

    const eventsForDay = events
      .filter(event => {
        const eventStart = event.date;
        const eventEnd = event.endDate || event.date;
        const eventCalendarsMatch = selectedCalendars.includes(event.calendarId);

        return (
          eventCalendarsMatch &&
          formattedDay >= eventStart &&
          formattedDay <= eventEnd
        );
      })
      .map(event => {
        let rangeClass = '';
        if (event.date === event.endDate || !event.endDate) {
          rangeClass = 'singleDay';
        } else if (formattedDay === event.date) {
          rangeClass = 'rangeStart';
        } else if (formattedDay === event.endDate) {
          rangeClass = 'rangeEnd';
        } else {
          rangeClass = 'rangeBetween';
        }

        return {
          ...event,
          rangeClass,
          tags: event.tags || [],
          variables: event.variables || {},
        };
      });

    const getEventDuration = (event: EventType) => {
      const start = new Date(event.date).getTime();
      const end = event.endDate ? new Date(event.endDate).getTime() : start;
      return end - start;
    };

    const sortByDurationAndStart = (a: EventType, b: EventType) => {
      const durationA = getEventDuration(a);
      const durationB = getEventDuration(b);

      if (durationA !== durationB) {
        return durationB - durationA;
      } else {
        return new Date(a.date).getTime() - new Date(b.date).getTime();
      }
    };

    const rangeEvents = eventsForDay
      .filter(event => event.rangeClass === 'rangeStart' || event.rangeClass === 'rangeBetween' || event.rangeClass === 'rangeEnd')
      .sort(sortByDurationAndStart);

    const untimedEvents = eventsForDay
      .filter(event => !event.startTime && event.rangeClass === 'singleDay')
      .sort((a, b) => a.eventRaw.localeCompare(b.eventRaw));

    const timedEvents = eventsForDay
      .filter(event => event.startTime)
      .sort((a, b) => {
        const timeCompare = (a.startTime || '').localeCompare(b.startTime || '');
        if (timeCompare !== 0) return timeCompare;
        return sortByDurationAndStart(a, b);
      });

    return [...rangeEvents, ...untimedEvents, ...timedEvents];
  };

  const formatDay = (day: Date): string => format(day, 'yyyy-MM-dd');

  // Handle click or range selection end
  const handleDayClick = (day: Date) => {
    if (isSelectingRange) {
      handleRangeEnd(day); // End range selection
    } else {
      const formattedDay = formatDay(day);
      if (selectedDay === formattedDay) {
        onDaySelect(null);
      } else {
        onDaySelect(formattedDay);
      }
    }
  };

  // Mouse down handler (for long press detection)
  const handleMouseDown = (day: Date) => {
    if (!isTouchDevice.current) {
      setStartDay(day);
    }
  };

  // Mouse up handler
  const handleMouseUp = (day: Date) => {
    handleLongPressCancel(); // Cancel long press if not triggered
    if (!isSelectingRange && !isTouchDevice.current) {
      if (startDay && day === startDay) {
        handleDayClick(day);
      }
      if (startDay) {
        onRangeSelect(formatDay(startDay), formatDay(day));
        setStartDay(null);
        setHoveredDay(null);
      }
    }
  };

  const handleTouchStart = (day: Date) => {
    isTouchDevice.current = true; // Mark as touch device
    handleLongPressStart(day); // Start long press detection
  };

  const handleMouseEnter = (day: Date) => {
    if (startDay) {
      onDaySelect(null);
      setHoveredDay(day);
    }
  };

  // Check if a day is in the selected range
  const isInSelectedRange = (day: Date) => {
    if (!selectedRange || selectedRange.length !== 2) return false;
    const formattedDay = formatDay(day);
    const [start, end] = selectedRange;
    return formattedDay >= start && formattedDay <= end;
  };

  const isInRange = (day: Date) => {
    if (!startDay || !hoveredDay) return false;
    const [start, end] = isBefore(startDay, hoveredDay) ? [startDay, hoveredDay] : [hoveredDay, startDay];
    return (isAfter(day, start) && isBefore(day, end)) || isSameDay(day, start) || isSameDay(day, end);
  };

  return (
    <div className="calendar">
      {calendar.map((week, i) => (
        <div className="week" key={i}>
          {week.map((day) => {
            const formattedDay = format(day, 'yyyy-MM-dd');
            const isToday = isSameDay(day, currentDate);
            const isSelected = selectedDay === formattedDay;
            const isPast = isBefore(startOfDay(day), currentDate);
            const inSelectedRangeHover = isInRange(day);
            const inSelectedRange = isInSelectedRange(day);
            const eventsForDay = getEventsForDay(day);

            return (
              <CalendarDay
                key={formattedDay}
                dayNumberOfMonth={getDate(day)}
                dayNumberOfWeek={getDay(day)}
                dayNumberOfYear={getDayOfYear(day)}
                year={day.getFullYear()}
                monthNumber={getMonth(day) + 1}
                isFirstOfMonth={isFirstDayOfMonth(day)}
                isLastOfMonth={isLastDayOfMonth(day)}
                isPublicHolidayInMyCountry={false}
                isToday={isToday}
                isPast={isPast}
                isSelected={isSelected}
                inSelectedRange={inSelectedRange}
                inSelectedRangeHover={inSelectedRangeHover}
                onClick={() => handleDayClick(day)}
                onMouseDown={() => handleMouseDown(day)}
                onMouseUp={() => handleMouseUp(day)}
                onTouchStart={() => handleTouchStart(day)}
                onTouchEnd={() => handleMouseUp(day)}
                onMouseEnter={() => handleMouseEnter(day)}
                events={eventsForDay}
              />
            );
          })}
        </div>
      ))}
    </div>
  );
};

export default Calendar;
