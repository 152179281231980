import { db } from './firebaseConfig';
import { collection, addDoc, query, where, getDocs, doc, updateDoc, onSnapshot, deleteDoc } from 'firebase/firestore';
import { EventType } from './types';
import { logAction, updateUserCalendarOrder } from './userService';

// Function to add a new event to a calendar
export const addEventToCalendar = async (
  calendarId: string,
  day: string,
  eventRaw: string,
  eventTitle: string,
  startTime: string | null,
  endTime: string | null,
  duration: string | null,
  endDate: string | null,
  tags: string[],
  variables: { [key: string]: string },
  userId: string,
  userName: string
) => {
  try {
    const eventsRef = collection(db, 'events');
    const newEvent = {
      calendarId,
      date: day,
      eventRaw,
      eventTitle,
      startTime,
      endTime,
      duration,
      endDate,
      tags,
      variables,
    };

    const eventDocRef = await addDoc(eventsRef, newEvent);

    // Update the order of calendars
    await updateUserCalendarOrder(userId, calendarId);
    console.log("Event added successfully, order updated");

    // Log user action and calendar action
    await logAction('user', userId, userName, 'added', calendarId, eventTitle, null, newEvent);
    await logAction('calendar', userId, userName, 'added', calendarId, eventTitle, null, newEvent);

  } catch (error) {
    console.error("Error adding event to calendar:", error);
    throw error;
  }
};

// Function to get events for a specific calendar
export const getEventsForCalendar = async (calendarId: string): Promise<EventType[]> => {
  try {
    const eventsRef = collection(db, 'events');
    const q = query(eventsRef, where('calendarId', '==', calendarId));
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map((doc) => ({
      id: doc.id,
      calendarId: doc.data().calendarId as string,
      date: doc.data().date as string,
      eventRaw: doc.data().eventRaw as string,
      eventTitle: doc.data().eventTitle as string,
      startTime: doc.data().startTime || null,
      endTime: doc.data().endTime || null,
      duration: doc.data().duration || null,
      endDate: doc.data().endDate || null,
      tags: doc.data().tags || [],
      variables: doc.data().variables || {},
    }));
  } catch (error) {
    console.error("Error getting events for calendar:", error);
    throw error;
  }
};

// Function to update an existing event in a calendar
export const updateEventInCalendar = async (
  eventId: string,
  calendarId: string,
  day: string,
  eventRaw: string,
  eventTitle: string,
  startTime: string | null,
  endTime: string | null,
  duration: string | null,
  endDate: string | null,
  tags: string[],
  variables: { [key: string]: string },
  oldEventData: EventType,
  userId: string,
  userName: string
) => {
  try {
    const eventRef = doc(db, 'events', eventId);
    const updatedEvent = {
      calendarId,
      date: day,
      eventRaw,
      eventTitle,
      startTime,
      endTime,
      duration,
      endDate,
      tags,
      variables,
    };

    await updateDoc(eventRef, updatedEvent);

    // Update the order of calendars
    await updateUserCalendarOrder(userId, calendarId);
    console.log("Event updated successfully, order updated");

    // Log user action and calendar action
    await logAction('user', userId, userName, 'modified', calendarId, eventTitle, oldEventData, updatedEvent);
    await logAction('calendar', userId, userName, 'modified', calendarId, eventTitle, oldEventData, updatedEvent);

  } catch (error) {
    console.error("Error updating event:", error);
    throw error;
  }
};

// Function to listen for events in a specific calendar in real-time
export const listenToEventsForCalendar = (calendarId: string, callback: (events: EventType[]) => void) => {
  const eventsRef = collection(db, 'events');
  const q = query(eventsRef, where('calendarId', '==', calendarId));

  return onSnapshot(q, (querySnapshot) => {
    const events: EventType[] = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      calendarId: doc.data().calendarId as string,
      date: doc.data().date as string,
      endDate: doc.data().endDate || null,
      eventRaw: doc.data().eventRaw as string,
      eventTitle: doc.data().eventTitle as string,
      startTime: doc.data().startTime || null,
      endTime: doc.data().endTime || null,
      duration: doc.data().duration || null,
      tags: doc.data().tags || [],
      variables: doc.data().variables || {},
    }));
    callback(events);
  });
};

// Function to delete an event from a calendar
export const deleteEventFromCalendar = async (
  eventId: string,
  calendarId: string,
  eventTitle: string,
  userId: string,
  userName: string
) => {
  try {
    const eventRef = doc(db, 'events', eventId);
    await deleteDoc(eventRef);

    console.log("Event deleted successfully");

    // Log user action and calendar action for deletion
    await logAction('user', userId, userName, 'deleted', calendarId, eventTitle, null, null);
    await logAction('calendar', userId, userName, 'deleted', calendarId, eventTitle, null, null);

  } catch (error) {
    console.error("Error deleting event:", error);
    throw error;
  }
};
