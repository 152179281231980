import { format, parseISO, differenceInDays } from 'date-fns';
import { fi } from 'date-fns/locale';

// Apufunktio: Tarkistaa, onko syöte string-muotoinen päivämäärä ja muuntaa sen Date-objektiksi
const parseDate = (date: Date | string | null): Date | null => {
  if (date === null) return null;
  return typeof date === 'string' ? parseISO(date) : date;
};

// Funktio: maanantai 7. lokakuuta
export const formatFullDateWithDayName = (date: Date | string | null): string => {
  const parsedDate = parseDate(date);
  return parsedDate ? format(parsedDate, 'cccc d. MMMM', { locale: fi }) : '';
};

// Funktio: ma 7.10
export const formatShortDayAndDate = (date: Date | string | null): string => {
    const parsedDate = parseDate(date);
    
    if (!parsedDate) return '';

    const shortDay = format(parsedDate, 'cccc', { locale: fi }).slice(0, 2).toLowerCase();
    
    return `${shortDay} ${format(parsedDate, 'd.M', { locale: fi })}`;
  };

// Funktio: 7.10
export const formatDayAndMonth = (date: Date | string | null): string => {
  const parsedDate = parseDate(date);
  return parsedDate ? format(parsedDate, 'd.M', { locale: fi }) : '';
};

// Funktio: 7.10.2024
export const formatFullDateWithYear = (date: Date | string | null): string => {
  const parsedDate = parseDate(date);
  return parsedDate ? format(parsedDate, 'd.M.yyyy', { locale: fi }) : '';
};

// Funktio: Laskee kahden päivämäärän välisen eron ja palauttaa sen muodossa "x päivää"
export const calculateDateDifference = (startDate: Date | string | null, endDate: Date | string | null): string => {
    const start = parseDate(startDate);
    const end = parseDate(endDate);
    
    if (!start || !end) return ''; // Jos jompikumpi päivämäärä puuttuu, palautetaan tyhjä merkkijono
  
    const daysDifference = differenceInDays(end, start)+1;
  
    return `${daysDifference} päivää`; // Palautetaan päivämäärien ero muodossa "x päivää"
  };
