import { db } from './firebaseConfig';
import { collection, addDoc, getDocs, getDoc, query, where, or, doc, updateDoc, deleteDoc, onSnapshot, arrayUnion, arrayRemove } from 'firebase/firestore';

// Function to create a new calendar with followers
export const createCalendar = async (userId: string, name: string) => {
  try {
    const calendarRef = await addDoc(collection(db, 'calendars'), {
      name,
      ownerId: userId,
      createdAt: new Date(),
      calendarType: 'private',
      followers: [],
      followerData: [],
    });

    // Update the user's calendar order
    const userDocRef = doc(db, 'users', userId);
    await updateDoc(userDocRef, {
      calendarOrder: arrayUnion(calendarRef.id)
    });

    return calendarRef.id;
  } catch (error) {
    console.error("Error creating calendar:", error);
    throw error;
  }
};

// Function to listen for real-time updates to the user's calendars
export const listenToCalendars = (userId: string, callback: (calendars: any[]) => void) => {
  const q = query(
    collection(db, 'calendars'),
    or(
      where('ownerId', '==', userId), // Own calendars
      where('calendarType', '==', 'public'), // Public calendars
      where('followers', 'array-contains', userId) // Shared calendars where user is a follower
    )
  );

  return onSnapshot(q, (snapshot) => {
    const updatedCalendars = snapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
    }));

    // Trigger callback with updated calendars
    callback(updatedCalendars);
  });
};

// Function to update an existing calendar
export const updateCalendar = async (calendarId: string, updateData: any) => {
  try {
    const calendarRef = doc(db, 'calendars', calendarId);
    await updateDoc(calendarRef, updateData);
  } catch (error) {
    console.error("Error updating calendar:", error);
    throw error;
  }
};

// Function to delete a calendar
export const deleteCalendar = async (calendarId: string) => {
  try {
    await deleteDoc(doc(db, 'calendars', calendarId));
    console.log(`Calendar ${calendarId} deleted.`);
  } catch (error) {
    console.error('Error deleting calendar:', error);
    throw error;
  }
};

// Function to join an existing shared calendar
export const joinToCalendar = async (calendarId: string, userId: string, userName: string): Promise<boolean> => {
  try {
    const calendarRef = doc(db, 'calendars', calendarId);
    const calendarDoc = await getDoc(calendarRef);

    if (calendarDoc.exists()) {
      const calendarData = calendarDoc.data();

      if (calendarData?.calendarType === 'shared') {
        const followerData = {
          userId,
          userName,
          joinedAt: new Date(),
        };

        // Add userId to the followers array and store user info in followerData
        await updateDoc(calendarRef, {
          followers: arrayUnion(userId),
          followerData: arrayUnion(followerData),
        });
        console.log(`User ${userName} (${userId}) added to calendar ${calendarId}`);

        // Add the calendar to user's calendar order
        const userDocRef = doc(db, 'users', userId);
        await updateDoc(userDocRef, {
          calendarOrder: arrayUnion(calendarId)
        });

        return true; // Success
      } else {
        console.error("Cannot join non-shared calendars");
        return false; // Not a shared calendar
      }
    } else {
      console.error("Calendar not found");
      return false; // Calendar does not exist
    }
  } catch (error) {
    console.error("Error joining calendar:", error);
    throw error;
  }
};

// Function to update the name of a calendar
export const updateCalendarName = async (calendarId: string, newName: string) => {
  try {
    const calendarRef = doc(db, 'calendars', calendarId);
    await updateDoc(calendarRef, {
      name: newName
    });
    console.log(`Calendar ${calendarId} updated to ${newName}`);
  } catch (error) {
    console.error('Error updating calendar name:', error);
    throw error;
  }
};

// Function to unfollow a calendar by removing the user from 'followers' and 'followerData'
export const unfollowCalendar = async (calendarId: string, userId: string): Promise<void> => {
  try {
    const calendarRef = doc(db, 'calendars', calendarId);
    const calendarDoc = await getDoc(calendarRef);

    if (calendarDoc.exists()) {
      const followersArray = calendarDoc.data()?.followers || [];
      const followerDataArray = calendarDoc.data()?.followerData || [];

      // Remove the user from the followers and followerData arrays
      const updatedFollowers = followersArray.filter((id: string) => id !== userId);
      const updatedFollowerData = followerDataArray.filter((follower: any) => follower.userId !== userId);

      // Update the calendar with the new followers and followerData arrays
      await updateDoc(calendarRef, {
        followers: updatedFollowers,
        followerData: updatedFollowerData,
      });

      // Remove the calendar from user's calendar order
      const userDocRef = doc(db, 'users', userId);
      await updateDoc(userDocRef, {
        calendarOrder: arrayRemove(calendarId)
      });

      console.log(`User ${userId} removed from calendar ${calendarId}`);
    } else {
      console.error("Calendar not found");
    }
  } catch (error) {
    console.error("Error unfollowing calendar:", error);
    throw error;
  }
};

// Function to check if a calendar is allowed for the current user and return full calendar data
export const checkIfCalendarAllowed = async (calendarId: string) => {
  if (!calendarId) {
    throw new Error('Invalid calendar ID');
  }

  try {
    const calendarRef = doc(db, 'calendars', calendarId);
    const calendarDoc = await getDoc(calendarRef);

    if (calendarDoc.exists()) {
      const calendarData = calendarDoc.data();

      if (calendarData?.calendarType === 'public') {
        return {
          id: calendarDoc.id,
          name: calendarData?.name,
          ownerId: calendarData?.ownerId,
          calendarType: calendarData?.calendarType,
          events: calendarData?.events || [],
          followers: calendarData?.followers || [],
          followerData: calendarData?.followerData || [],
        };
      } else {
        console.error('Permission denied');
        return null; // Access denied
      }
    } else {
      console.error('Calendar does not exist');
      return null;
    }
  } catch (error) {
    console.error('Error checking calendar:', error);
    throw error;
  }
};
