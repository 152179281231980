// src/firebaseConfig.ts

// Import Firebase modules
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth, GoogleAuthProvider  } from 'firebase/auth';

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyAVQDq4LhH-lb747TRCKKeO43duKxGwE_M",
    authDomain: "glendr.firebaseapp.com",
    projectId: "glendr",
    storageBucket: "glendr.appspot.com",
    messagingSenderId: "330558580044",
    appId: "1:330558580044:web:b3d7b9927f5504ad9a5be9"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore
const db = getFirestore(app);

// Initialize Firebase Authentication
const auth = getAuth(app);

// Initialize Google Auth Provider
const googleProvider = new GoogleAuthProvider();

// Export the initialized Firebase services and provider
export { db, auth, googleProvider };
