import React, { useState, useEffect } from 'react';
import Calendar from './components/Calendar/Calendar';
import Header from './components/Header/Header';
import EventPanel from './components/EventPanel/EventPanel';
import { listenToCalendars, checkIfCalendarAllowed } from './calendarService';
import { listenToEventsForCalendar } from './eventService';
import { login, logout, loginAnonymously } from './authService';
import { createOrUpdateUserSettings, getUserData } from './userService';
import { auth } from './firebaseConfig';
import { onAuthStateChanged } from 'firebase/auth';
import './global.css';
import { EventType, CalendarType } from './types';

const App: React.FC = () => {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [isAnonymous, setIsAnonymous] = useState<boolean>(false);
  const [userId, setUserId] = useState<string | null>(null);
  const [calendars, setCalendars] = useState<{ [key: string]: CalendarType }>({});
  const [calendarStatus, setCalendarStatus] = useState<string>('');
  const [selectedCalendars, setSelectedCalendars] = useState<string[]>([]);
  const [events, setEvents] = useState<EventType[]>([]);
  const [darkMode, setDarkMode] = useState<boolean>(false);
  const [selectedDay, setSelectedDay] = useState<string | null>(null);
  const [prevSelectedDay, setPrevSelectedDay] = useState<string | null>(null);
  const [selectedRange, setSelectedRange] = useState<[string, string] | null>(null);
  const [urlCalendarLoaded, setUrlCalendarLoaded] = useState<boolean>(false); // Uusi tila
  const [userName, setUserName] = useState<string>('');
  const [calendarOrder, setCalendarOrder] = useState<string[]>([]); // Add state for calendar order

  // Handle user login state
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setUserId(user.uid);
        setIsLoggedIn(true);
        setIsAnonymous(user.isAnonymous);
        await createOrUpdateUserSettings(user.uid);
      } else {
        setUserId(null); // Varmistetaan, ettei ole vanhentuneita userId-tietoja
        setIsLoggedIn(false);
        setIsAnonymous(false);
      }
    });

    return () => unsubscribe();
  }, []);

  // Function to handle anonymous login when needed
  const handleAnonymousLogin = async () => {
    try {
      const anonUser = await loginAnonymously();
      setUserId(anonUser.uid);
      setIsLoggedIn(true);
      setIsAnonymous(true);
      await createOrUpdateUserSettings(anonUser.uid);
    } catch (error) {
      console.error('Anonymous login error:', error);
    }
  };

  // Select calendar from URL if available
  useEffect(() => {
    const loadCalendarIfNeeded = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const calendarId = urlParams.get('calendar');

      console.log(`Checking calendar ID from URL: ${calendarId}`);

      if (calendarId && calendarId.length === 20) {
        try {
          const calendarData = await checkIfCalendarAllowed(calendarId);

          if (calendarData) {
            console.log('Calendar found:', calendarData);

            const fullCalendarData: CalendarType = {
              id: calendarData.id,
              name: calendarData.name || 'Untitled Calendar',
              ownerId: calendarData.ownerId || 'Unknown Owner',
              events: calendarData.events || {},
              followers: calendarData.followers || [],
            };

            setCalendars((prevCalendars) => ({
              ...prevCalendars,
              [fullCalendarData.id]: fullCalendarData,
            }));

            setSelectedCalendars((prev) => [...prev, fullCalendarData.id]);
            setCalendarStatus(`${calendarId}`);

            listenToEventsForCalendar(fullCalendarData.id, (updatedEvents) => {
              setEvents((prevEvents) => {
                const filteredEvents = prevEvents.filter(event => event.calendarId !== fullCalendarData.id);
                return [...filteredEvents, ...updatedEvents];
              });
            });
          } else {
            console.log('No calendar found or access denied.');
            setCalendarStatus('');
          }
        } catch (error) {
          setCalendarStatus('');
          console.error('Error loading calendar from URL:', error);
        }
      } else {
        console.log('No valid calendar ID found in URL.');
      }
    };

    if (userId) { // Varmistetaan, että userId on asetettu ennen kalenterin lataamista
      loadCalendarIfNeeded();
    }
  }, [userId]);

  // Get user data after login
  useEffect(() => {
    if (userId) {
      const loadUserData = async () => {
        try {
          const userData = await getUserData(userId);
          setDarkMode(userData.settings?.darkMode || false);
          setSelectedCalendars(userData.visibleCalendars || []);
          setUserName(userData.publicName || 'Guest');
          setCalendarOrder(userData.calendarOrder || []);
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      };
      loadUserData();
    }
  }, [userId]);

  // Fetch and set visible calendars for the user
  useEffect(() => {
    if (userId && !urlCalendarLoaded) {
      const unsubscribe = listenToCalendars(userId, (updatedCalendars) => {
        const calendarsObj = updatedCalendars.reduce((acc: { [key: string]: CalendarType }, calendar) => {
          acc[calendar.id] = calendar;
          return acc;
        }, {});
        setCalendars((prevCalendars) => ({ ...prevCalendars, ...calendarsObj }));
        setSelectedCalendars(Object.keys(calendarsObj));
      });

      return () => unsubscribe();
    }
  }, [userId, urlCalendarLoaded]);

  // Listen to events for selected calendars
  useEffect(() => {
    const unsubscribeListeners: (() => void)[] = [];

    if (selectedCalendars.length > 0) {
      selectedCalendars.forEach((calendarId) => {
        const unsubscribe = listenToEventsForCalendar(calendarId, (updatedEvents) => {
          setEvents((prevEvents) => {
            const filteredEvents = prevEvents.filter(event => event.calendarId !== calendarId);
            return [...filteredEvents, ...updatedEvents];
          });
        });
        unsubscribeListeners.push(unsubscribe);
      });
    }

    return () => {
      unsubscribeListeners.forEach((unsubscribe) => unsubscribe());
    };
  }, [selectedCalendars]);


  const handleDaySelection = (day: string | null) => {
    setPrevSelectedDay(day);

    if (day === prevSelectedDay) {
      clearSelection();
      setPrevSelectedDay(null);
    } else {
      setSelectedDay(day);
      setSelectedRange(null);
    }
  };

  const handleRangeSelect = (startDay: string, endDay: string) => {
    const sortedRange = [startDay, endDay].sort();
    if (sortedRange.length === 2) {
      setSelectedRange([sortedRange[0], sortedRange[1]]);
    }
    setSelectedDay(null);
  };

  const clearSelection = () => {
    setSelectedDay(null);
    setSelectedRange(null);
  };

  const handleLogin = async (email: string, password: string) => {
    try {
      await login(email, password);
    } catch (error) {
      console.error('Login error:', error);
    }
  };

  const handleLogout = async () => {
    try {
      await logout();
      setCalendars({});
      setEvents([]);
      setSelectedCalendars([]);
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  // Funktio järjestämään kalenterit calendarOrder perusteella
  const getOrderedCalendars = (): { [key: string]: CalendarType } => {
    const orderedCalendars: { [key: string]: CalendarType } = {};
    const unorderedCalendars: { [key: string]: CalendarType } = {};

    // Lisää kalenterit järjestyksessä calendarOrder-kentän mukaan
    calendarOrder.forEach((id) => {
      if (calendars[id]) {
        orderedCalendars[id] = calendars[id];
      }
    });

    // Lisää kalenterit, jotka eivät ole calendarOrder-listassa
    Object.keys(calendars).forEach((id) => {
      if (!calendarOrder.includes(id)) {
        unorderedCalendars[id] = calendars[id];
      }
    });

    // Palauta yhdistetty lista, jossa järjestetyt kalenterit ovat ensin
    return { ...orderedCalendars, ...unorderedCalendars };
  };

  return (
    <div className={`app ${darkMode ? 'dark-mode' : ''}`}>
      <Header
        userId={userId}
        isLoggedIn={isLoggedIn}
        isAnonymous={isAnonymous}
        onLogin={handleLogin}
        onLogout={handleLogout}
        calendars={getOrderedCalendars()} // Use ordered calendars
        events={events}
        selectedCalendars={selectedCalendars}
        setSelectedCalendars={setSelectedCalendars}
        calendarFromUrl={calendarStatus}
        userName={userName} // Passataan käyttäjän nimi
        darkMode={darkMode}  // Passataan dark mode asetus
        setUserName={setUserName} // Päivitetään nimi App:sta
        setDarkMode={setDarkMode} // Päivitetään dark mode App:sta
      />

      <Calendar
        selectedDay={selectedDay}
        selectedRange={selectedRange}
        onDaySelect={handleDaySelection}
        onRangeSelect={handleRangeSelect}
        calendars={getOrderedCalendars()} // Use ordered calendars
        events={events}
        selectedCalendars={selectedCalendars}
      />
      {userId && (
        <EventPanel
          selectedDay={selectedDay}
          selectedRange={selectedRange}
          onDayClick={handleDaySelection}
          onClearSelection={clearSelection}
          calendars={getOrderedCalendars()} // Use ordered calendars
          events={events}
          userId={userId}
          userName={userName}
          selectedCalendars={selectedCalendars}
          setSelectedCalendars={setSelectedCalendars}
        />
      )}
    </div>
  );
};

export default App;